.l {
  &-max-wrap {
  	display: block;
  	max-width: $maxContentWidth + ($outerMarginXLarge*2);
  	padding-left: $outerMarginSmall;
  	padding-right: $outerMarginSmall;
  	margin-left: auto;
  	margin-right: auto;

  	@include bigger-than($widthM) {
      padding-left: $outerMarginMedium;
      padding-right: $outerMarginMedium;
    }
    @include bigger-than($widthMPlus) {
      padding-left: $outerMarginMediumPlus;
      padding-right: $outerMarginMediumPlus;
    }
    @include bigger-than($widthL) {
      padding-left: $outerMarginLarge;
      padding-right: $outerMarginLarge;
    }
    @include bigger-than($widthLPlus) {
      padding-left: $outerMarginLargePlus;
      padding-right: $outerMarginLargePlus ;
    }
    @include bigger-than($widthXL) {
      padding-left: $outerMarginXLarge;
      padding-right: $outerMarginXLarge;
    }
  }

  &-center-block {
  	margin-left: auto;
  	margin-right: auto;
  }

  &-pos-abs {
    position: absolute;
  }

  &-fill {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-objectfit {
    .objectfit & {
        width: 100%;
        height: 100%;

        &--contain {
            object-fit: contain;
        }

        &--cover {
            object-fit: cover;
        }
    }

    .no-objectfit & {
        width: auto;
        height: auto;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &--contain {
            max-width: 100%;
            max-height: 100%;
        }

        &--cover {
            max-width: none;
            max-height: none;
            min-width: 100%;
            min-height: 100%;
        }
    }
  }
}
