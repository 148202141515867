/**
* --- Base setup and resets --------------------------------------------------------------------------------------------
*/

* { box-sizing: border-box; }
html { font-size: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; height:100%; }

body {
	position: relative;
  @include fontDefault;
	font-weight: $fontWeightRegular;
	font-size: 16px;
	line-height: (22/15);
	color: $colorText;
	background: $colorBackground;
	overflow-x: hidden;
}

@include breakpointer($breakpointMap);

h1, h2, h3, h4, h5, h6 { font-size: 1em; display: block; margin: 0; font-weight: $fontWeightRegular; }
p, small, li { display: block; margin: 0; }
ul, ol { list-style: none; list-style-image: none; margin: 0; padding: 0; }
img { max-width: 100%; border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; display:inline-block;width: 100%;
height: auto; }
figure { margin: 0; }

a, input, button { -ms-touch-action: none !important; }
input, textarea { -webkit-appearance: none; -webkit-border-radius: 0; }
input[type="radio"] { -webkit-appearance: radio; }
input[type="checkbox"] { -webkit-appearance: checkbox; }
textarea { resize: none; }
select { -webkit-border-radius: 0; }
/* input:-webkit-autofill { -webkit-box-shadow: 0 0 0 1000px $colorInputBackground inset; } */
input::-ms-clear { display: none; }
input[type="search"], input[type="text"] { box-sizing: border-box; }
fieldset { border: 0; padding: 0; margin: 0; }
picture { display: block; }
address { font-style: normal; }

a { color: inherit; text-decoration: none; }
a:hover {  }

b, strong {
	font-weight: $fontWeightBold;
}
i, em {
	font-style: normal;
}

button:not(:focus) { outline: 0; }

.no-outline {
	button, a, input, label, textarea { outline: none !important; }
}

.main {
	padding-top: 60px;

	@include bigger-than($widthM) {
		padding-top: 80px;
	}

	@include bigger-than($widthL) {
		padding-top: 130px;
	}
	@include bigger-than($widthXL) {
		padding-top: 160px;
	}
}

/* fade image in after load */
.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}
.lazyloaded {
  opacity: 1;
  transition: opacity 2s $easeOutQuad;
}

.no-js {
  img.lazyload {
    display: none;
  }
}

.wysiwyg {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $colorPurple;
    background: transparentize($colorPurple, 0.9);
    box-shadow: inset 0 -0.28em 0 #fff, inset 0 0.37em 0 #fff;
    line-height: normal;

    .no-touch & {
      transition: background 0.2s;

      &:hover {
        background: transparentize($colorPurple, 0.85);
      }
    }
  }
}

.image-wrap {
  width: 100%;
  height: 0;
}

::-webkit-input-placeholder {
   color: $colorText;
}

:-moz-placeholder { /* Firefox 18- */
   color: $colorText;  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: $colorText;  
}

:-ms-input-placeholder {
   color: $colorText;  
}
