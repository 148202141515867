.contact {
  &__image-wrap {
    width: 100%;
    height: 0;
    padding-bottom: 60%;

    margin-bottom: $outerMarginSmall;

    @include bigger-than($widthSPlus) {
      margin-bottom: 0;
    }

    .grid-item:last-child & {
      margin-bottom: 0;
    }
  }

  &__image {

  }

  &__map-wrap {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    position: relative;

    @include bigger-than($widthM) {
      padding-bottom: 42%;
    }
  }

  &__map {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
