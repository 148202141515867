.page-header {
  pointer-events: auto;
  height: 69px;

  @include bigger-than($widthMPlus) {
    height: 140px;
  }

  @include bigger-than($widthLPlus) {
    height: 186px;
  }

  .dark-header & {
    @include bigger-than($widthMPlus) {
      height: 0;
    }
  }
}
