.spacing {
  &-bottom-default {
    margin-bottom: $spacingSmall;

    @include bigger-than($widthM) {
      margin-bottom: $spacingMedium;
    }

    @include bigger-than($widthMPlus) {
      margin-bottom: $spacingMediumPlus;
    }

    @include bigger-than($widthL) {
      margin-bottom: $spacingLarge;
    }

    @include bigger-than($widthLPlus) {
      margin-bottom: $spacingLargePlus;
    }

    &--reduce {
      @include bigger-than($widthM) {
        margin-bottom: $spacingMedium !important;
      }
    }
  }

  &-bottom-1\/4 {
    margin-bottom: $spacingSmall/4;

    @include bigger-than($widthM) {
      margin-bottom: $spacingMedium/4;
    }

    @include bigger-than($widthMPlus) {
      margin-bottom: $spacingMediumPlus/4;
    }

    @include bigger-than($widthL) {
      margin-bottom: $spacingLarge/4;
    }

    @include bigger-than($widthLPlus) {
      margin-bottom: $spacingLargePlus/4;
    }
  }

  &-bottom-2\/3 {
    margin-bottom: $spacingSmall*(2/3);

    @include bigger-than($widthM) {
      margin-bottom: $spacingMedium*(2/3);
    }

    @include bigger-than($widthMPlus) {
      margin-bottom: $spacingMediumPlus*(2/3);
    }

    @include bigger-than($widthL) {
      margin-bottom: $spacingLarge*(2/3);
    }

    @include bigger-than($widthLPlus) {
      margin-bottom: $spacingLargePlus*(2/3);
    }
  }
}
