.t-size {
	&-l {
		font-size: 36px;
		line-height: (90/110);

    @include bigger-than($widthSPlus) {
      font-size: 48px;
    }

    @include bigger-than($widthM) {
      font-size: 70px;
    }

    @include bigger-than($widthL) {
      font-size: 90px;
    }

    @include bigger-than($widthLPlus) {
      font-size: 110px;
    }
	}

  &-ml {
    font-size: 36px;
    line-height: (80/68);

    @include bigger-than($widthM) {
      font-size: 48px;
    }

    @include bigger-than($widthL) {
      font-size: 60px;
    }

    @include bigger-than($widthLPlus) {
      font-size: 68px;
    }
  }

  &-m {
    font-size: 24px;
    line-height: (52/34);

    @include bigger-than($widthM) {
      font-size: 28px;
    }

    @include bigger-than($widthL) {
      font-size: 34px;
    }

    &.wysiwyg {
      p {
        margin-bottom: (52/34)*1em;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h3 {
        margin-bottom: (52/34)*1em;
      }
    }
  }

  &-s {
    font-size: 20px;
    line-height: (30/20);
  }

  &-copy {
    font-size: 22px;
    line-height: (30/22);

    @include bigger-than($widthMPlus) {
      line-height: (34/22);
    }

    &.wysiwyg {
      p {
        margin-bottom: (30/22)*1em;

        @include bigger-than($widthMPlus) {
          margin-bottom: (34/22)*1em;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        padding-top: 4px;
        margin-bottom: (30/22)*1em;

        &:last-child {
          margin-bottom: 0;
        }
      }

      li {
        position: relative;
        line-height: 1.1;
        padding-left: 20px;
        margin-bottom: 0.75em;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 13px;
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background: $colorText;
        }
      }
    }
  }

  &-h3 {
    font-size: 30px;
    line-height: 1;

    @include bigger-than($widthM) {
      font-size: 20px;
      line-height: (30/22);
    }

    @include bigger-than($widthMPlus) {
      font-size: 22px;
      line-height: (34/22);
    }
  }
}

// TYPOGRAPHY MODIFIERS
.t-block {
  display: block;
}

.t-spacing-m {
  margin-bottom: (52/34)*1em;
}

.t-spacing-1 {
  margin-bottom: 1em;
}

.t-spacing-s {
  margin-bottom: 1.5em;
}

.t-lh1 {
  line-height: 1;
}

.t-bold {
  font-weight: $fontWeightBold;
}

.t-upper {
  text-transform: uppercase;
}

.t-left {
  text-align: left;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.t-link {
  color: $colorPurple;
  background: transparentize($colorPurple, 0.9);
  box-shadow: inset 0 -0.28em 0 #fff, inset 0 0.37em 0 #fff;
  line-height: normal;

  .no-touch & {
    transition: background 0.2s;

    &:hover {
      background: transparentize($colorPurple, 0.85);
    }
  }
}
