.recent {
  width: 100%;
  overflow: hidden;

  &__projects {
    @extend .clearfix;
    display: block;
    width: calc(100% + 10px);
    margin-left: -5px;
  }

  &__project {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;

    @include bigger-than($widthM) {
      width: 33.3333%;
      float: left;

      &:last-child:first-child {
        width: 100%;
      }

      &:nth-last-child(3n+2):first-child {
        width: 50%;
      }

      &:nth-last-child(3n+2):first-child ~ &:nth-child(2) {
        width: 50%;
      }

      &:nth-last-child(3n+1):first-child {
        width: 50%;
      }

      &:nth-last-child(3n+1):first-child:last-child {
        width: 100%;
      }

      &:nth-last-child(3n+1):first-child ~ &:nth-child(-n+4) {
        width: 50%;
      }
    }
  }

  &__link {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $colorBlack;
    opacity: 0.25;
    z-index: 1;
  }

  &__hover-overlay {
    // display: none;
    // .no-touch & {
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background: $colorPurple;
    //   opacity: 0;
    //   z-index: 2;
    // }
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $colorPurple;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.2s;
    transform: translateZ(0);

    a:hover & {
      opacity: 0.5;
    }
  }

  &__text-wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    font-size: 24px;
    -webkit-font-smoothing: antialiased;
    color: $colorWhite;
    width: 100%;

    @include bigger-than($widthM) {
      font-size: 20px;
    }

    @include bigger-than($widthMPlus) {
      font-size: 28px;
    }

    @include bigger-than($widthL) {
      font-size: 34px;
    }
  }

  &__name {
    font-size: 1em;
  }

  &__text {
    display: block;
    font-size: 0.6em;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -0.5em;
    transform: translateY(100%) translateZ(0);
    opacity: 0;
    transition: opacity 0.2s;

    a:hover & {
      opacity: 1;
    }
  }
}
