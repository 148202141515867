.page-footer {
  padding-top: 50px;
  padding-bottom: 50px;

  @include bigger-than($widthM) {
    padding-bottom: 33px;
  }

  border-top: 1px solid #555555;

  .is-frontpage & {
    background: $colorPurple;
    color: $colorWhite;
    border-top: 10px solid $colorWhite;
  }

  &__items {
    padding-bottom: 67px;

    @include bigger-than($widthM) {
      padding-right: 127px;
      padding-bottom: 0;
    }
  }

  &__item {
    font-size: 17px;
    line-height: (28/17);
    display: block;
    margin-bottom: 1em;

    @include bigger-than($widthM) {
      vertical-align: top;
      display: inline-block;
      margin-right: 30px;
    }

    @include bigger-than($widthMPlus) {
      margin-right: 35px;
    }

    @include bigger-than($widthL) {
      margin-right: 80px;
    }

    @include bigger-than($widthLPlus) {
      margin-right: 100px;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      text-decoration: underline;
    }
  }

  &__logo {
    display: block;
    width: 107px;
    height: 49px;
    position: absolute;
    bottom: 50px;
    left: $outerMarginSmall;

    @include bigger-than($widthM) {
      border: auto;
      left: auto;
      top: 50%;
      transform: translateY(-50%);
      right: $outerMarginMedium;
    }

    @include bigger-than($widthMPlus) {
      right: $outerMarginMediumPlus;
    }

    @include bigger-than($widthL) {
      right: $outerMarginLarge;
    }

    @include bigger-than($widthLPlus) {
      right: $outerMarginLargePlus;
    }

    @include bigger-than($widthXL) {
      right: $outerMarginXLarge;
    }

    svg {
      width: 107px;
      height: 49px;
    }

    path {
      .is-frontpage & {
        fill: currentColor;
      }
    }
  }
}
