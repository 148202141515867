@mixin screenreader {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin bigger-than($breakpoint) {
    @media (min-width: #{$breakpoint}px) {
        @content;
    }
}

@mixin smaller-than($breakpoint) {
    @media (max-width: #{$breakpoint - 1}px) {
        @content;
    }
}

@mixin verticallyAligned($pos:relative) {
    position: $pos;
    top: 50%;
    transform: translateY(-50%);
}


@function get-breakpoint-width-from-map($name, $breakpoints) {
    @if map-has-key($breakpointMap, $name) {
        @return map-get($breakpointMap, $name);
    } @else {
        @warn "Breakpoint #{$name} wasn't found in $breakpointMap.";
    }
}

@mixin get-breakpoint-width($breakpoint) {
    $min-width: 0;

    @if $breakpoint {
        @if type-of($breakpoint) == number {
            $min-width: $breakpoint;
        } @else {
            $min-width: get-breakpoint-width-from-map($breakpoint, $breakpointMap);
        }
    }

    @if $min-width != 0 {
        @media (min-width: #{$min-width}px) {
            @content;
        }
    }
}

@function grid-columns($span, $columns) {
    @if $span >= 1 {
        // Convert the given span to a fraction of the columns
        @return percentage($span / $columns);
    } @else {
        // A fraction has been supplied so lets just converting it to a percentage
        @return percentage($span);
    }
}

@mixin _grid-widths($columns, $breakpoint: null) {
    $breakpoint-suffix: '';

    @if $breakpoint != null {
        $breakpoint-suffix: \@#{$breakpoint};
    }

    @each $column in $columns {
        @if $column == 1 {
            // no point outputting 100% several times so lets just do it once
            .grid-#{$column}\/#{$column}#{$breakpoint-suffix} {
                width: 100% !important;
            }
        } @else {
            @for $i from 1 to $column {
                .grid-#{$i}\/#{$column}#{$breakpoint-suffix} {
                    width: grid-columns($i / $column, $columns) !important;
                    float: left;
                }
                .grid-#{$i}\/#{$column}#{$breakpoint-suffix}--right {
                    width: grid-columns($i / $column, $columns) !important;
                    float: right;
                    margin-left: auto;
                    // order: 99;
                }
                .push-#{$i}#{$breakpoint-suffix} {
                    margin-left: grid-columns($i / $column, $columns) !important;
                }

                .push-0#{$breakpoint-suffix} {
                    margin-left: 0 !important;
                }
            }
        }
    }
}

@mixin generate-grid($columns, $breakpoints) {
    @include _grid-widths($columns);
    @each $breakpoint in $breakpoints {
        @include get-breakpoint-width('#{$breakpoint}') {
            @include _grid-widths($columns, $breakpoint);
        }
    }
}

@mixin breakpointer($breakpoints) {
    body:after {
        display: none;
        position: relative;
        z-index: 1;

        @each $key, $val in $breakpoints {
            @include bigger-than($val) {
                z-index: $val;
            }
        }
    }
}
