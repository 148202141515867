.map {
  &__marker {
    cursor: inherit !important;
    background: $colorLightGray;
    color: $colorWhite;
    font-size: 18px;
    line-height: 1;
    font-family: $fontDefault;
    font-weight: $fontWeightRegular;
    padding: 8px 8px 10px;
    transform: translateX(-50%);
    white-space: nowrap;
    // pointer-events: none;

    .is-frontpage & {
      transition: background 0.2s;
      background: $colorPurple;
    }

    .has-multiple-points & {
      font-size: 14px;
      cursor: pointer !important;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -9px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px 9px 0 9px;
      border-color: $colorLightGray transparent transparent transparent;

      .is-frontpage & {
        transition: border-color 0.2s;
        border-color: $colorPurple transparent transparent transparent;
      }

    }

    &:hover {
      z-index: 9999999;
      .is-frontpage & {
        background: #680165;
      }


      &:after {
        .is-frontpage & {
          border-color: #680165 transparent transparent transparent;
        }
      }
    }
  }
}
