.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  color: $colorBlack;
  background: $colorWhite;
  opacity: 0;
  display: none;

  @include bigger-than($widthM) {
    color: $colorWhite;
    background: $colorBlack;
  }
  // pointer-events: all;

  .flickity-prev-next-button.next {
    right: 0;
  }

  .flickity-prev-next-button.previous {
    left: 0;
  }

  .flickity-prev-next-button {
    border-radius: 0;
  }

  &__close {
    top: 5px;
    right: 0;
    z-index: 999;

    path {
      fill: #bc9cc6;
    }
  }

  &__slide {
    background: $colorWhite;
    padding: 60px 5px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include bigger-than($widthM) {
      padding: 45px 30px 30px;
      background: $colorBlack;
    }
  }

  &__image-outer, picture {
    // height: 100%;
    width: 100%;
    flex: 0 1 auto;
    overflow: hidden;
    position: relative;
    display: flex;
  }

  &__image-caption {
    flex: 0 0 auto;
    display: block;
    padding: 10px 20px 0;
    text-align: center;
  }

  img {
    width: 100%;
    height: auto !important;
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }
}
