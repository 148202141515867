.grid-wrap {
  @extend .clearfix;
  display: block;
  max-width: $maxContentWidth + ($outerMarginXLarge*2);
  padding-left: $outerMarginSmall - ($gutterSmall/2);
  padding-right: $outerMarginSmall - ($gutterSmall/2);
  margin-left: auto;
  margin-right: auto;

  @include bigger-than($widthM) {
    padding-left: $outerMarginMedium - ($gutterMedium/2);
    padding-right: $outerMarginMedium - ($gutterMedium/2);
  }
  @include bigger-than($widthMPlus) {
    padding-left: $outerMarginMediumPlus - ($gutterMediumPlus/2);
    padding-right: $outerMarginMediumPlus - ($gutterMediumPlus/2);
  }
  @include bigger-than($widthL) {
    padding-left: $outerMarginLarge - ($gutterLarge/2);
    padding-right: $outerMarginLarge - ($gutterLarge/2);
  }
  @include bigger-than($widthLPlus) {
    padding-left: $outerMarginLargePlus - ($gutterLargePlus/2);
    padding-right: $outerMarginLargePlus - ($gutterLargePlus/2);
  }
  @include bigger-than($widthXL) {
    padding-left: $outerMarginXLarge - ($gutterXLarge/2);
    padding-right: $outerMarginXLarge - ($gutterXLarge/2);
  }
}

.grid-item {
  width: 100%;
  padding-left: $gutterSmall/2;
  padding-right: $gutterSmall/2;
  @include bigger-than($widthM) {
    padding-left: $gutterMedium/2;
    padding-right: $gutterMedium/2;
  }
  @include bigger-than($widthMPlus) {
    padding-left: $gutterMediumPlus/2;
    padding-right: $gutterMediumPlus/2;
  }
  @include bigger-than($widthL) {
    padding-left: $gutterLarge/2;
    padding-right: $gutterLarge/2;
  }
  @include bigger-than($widthLPlus) {
    padding-left: $gutterLargePlus/2;
    padding-right: $gutterLargePlus/2;
  }
  @include bigger-than($widthXL) {
    padding-left: $gutterXLarge/2;
    padding-right: $gutterXLarge/2;
  }
}

.grid-clear {
  clear:both;
}

// @include generate-grid(2, $grid-breakpoints-small);
@include generate-grid(16, $grid-breakpoints);
