.project {

  [data-has-zoom] {
    cursor: zoom-in;
  }

  &__intro-text {
    @include bigger-than($widthL) {
      font-size: 32px;
    }
  }
  &__section {
    clear: both;
    padding-top: 40px;
    position: relative;
  }

  &__section-heading {
    color: $colorText;
    margin-bottom: 1em;

    @include bigger-than($widthM) {
      color: $colorLightGray;
    }

    &--mobile {
      display: block;

      @include bigger-than($widthM) {
        display: none;
      }
    }

    &--hide-mobile {
      @include smaller-than($widthM) {
        position: absolute;
      	overflow: hidden;
      	width: 1px;
      	height: 1px;
      	clip: rect(0 0 0 0);
      	border: 0;
      	padding: 0;
      	margin: 0 -1px -1px 0;
      }
    }

    @include bigger-than($widthM) {
      opacity: 0;
    }

  }

  &__block {
    @include bigger-than($widthLPlus) {
      margin-bottom: 130px;
    }
  }

  &__image-wrap {
    width: 100%;
    height: 0;

    &--full {
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &--has-mobile {
      @include smaller-than($widthM) {
        padding-bottom: 0 !important;
        height: auto !important;
      }
    }

    &--three {
      padding-bottom: 130%;
    }

    &--slideshow {
        padding-bottom: percentage(1/1.5);
    }
  }

  &__three-images-wrap {
    &.-has-slideshow {
      position: relative;
      // height: 286px;

      @include bigger-than($widthS) {
        // height: 364px;
      }
    }

    @include smaller-than($widthMPlus) {
      padding: 0;
    }
  }

  &__three-images-outer {
    .-has-slideshow & {
      // height: 286px;
      position: relative;;
      top: 0;
      width: calc(100% + #{($outerMarginSmall*2) - $gutterSmall});
      left:-($outerMarginSmall - ($gutterSmall/2));

      @include bigger-than($widthS) {
        // height: 364px;
      }
    }

  }

  &__three-images-image {
    width: 33.333333%;
    float: left;

    .-has-slideshow & {
      width: 240px;
      // height: 286px;
      float: none;

      @include bigger-than($widthS) {
        width: 300px;
        // height: 364px;
      }
    }
  }

  &__details-info {
    line-height: normal;
  }

  &__details-heading {
    display: block;
    color: $colorGray;
    line-height: normal;
  }

  &__details-image {
    @include smaller-than($widthM) {
      max-width: 400px;
    }
  }
  &__details-text {
    display: block;
    margin-bottom: 1em;
    line-height: 1.2;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__big-text {
    text-align: left;
    display: inline-block;
    padding-top: 40px;

    @include bigger-than($widthM) {
      padding-top: 20px;
    }

    .-is-first & {
      padding-top: 0;
    }

    br {
      @include smaller-than($widthSPlus) {
        display: none;
      }
    }
  }

  &__map-wrap {
    width: 100%;
    height: 400px;
  }

  &__map {
    width: 100%;
    height: 400px;
  }

  &__map-image-block {
    @include bigger-than($widthL) {
      margin-left: 0 !important;
    }
  }

  &__map-image-wrap {
    width: 100%;
    height: 0;
    padding-bottom: (9/16)*100%;

    @include bigger-than($widthL) {
      padding-bottom: 0;
      max-width: 300px;
      height: 400px;
      overflow: hidden;
    }
  }

  &__map-image {
    width: 100%;
    height: auto;

    @include bigger-than($widthL) {
      width: 300px;
      height: 400px;
      position: relative;
      left: 50%;
      margin-left: -150px;
    }
  }

  &__form {
    font-size: 20px;
    position: relative;
  }

  &__form-request {
    display: none;
  }

  &__form-input {
    display: block;
    width: 100%;
    background: #e5e5e5;
    border: none;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid transparent;

    &.has-error {
      border: 1px solid $colorRed;
    }
  }

  &__form-button {
    appearance: none;
    background: $colorPurple;
    border: none;
    color: $colorWhite;
    display: inline-block;
    line-height: 1;
    padding: 10px 30px 13px;
  }

  &__form-check {
    margin-bottom: 20px;
  }

  &__form-error {
    display: block;
    margin-top: 1em;
    color: $colorRed;
  }

  &__form-success {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }

  &__image-caption {
    display: block;
    margin-top: 10px;
    max-width: 600px;
  }

  &__half-image-outer {
    max-width: 300px;
    margin: 0 auto;

    @include bigger-than($widthSPlus) {
        width: 50%;
        max-width: none;
    }

    @include bigger-than($widthMPlus) {
        width: 40%;
    }
  }

  &__slideshow {
      &-outer {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: calc(#{percentage(1/1.5)} + 40px);
      }

      &-caption {
          display: block;
          margin-top: 10px;
          width: 100%;
          padding: 0 20px;
      }

      &-item {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0px 10px;
      }

      &-item-inner {
          width: 100%;
          // position: absolute;
          // top: 0;
          // left: 0;
      }

      .flickity-viewport {
          position: absolute;
          width: 100%;
          height: calc(100% + 40px);
        }
        .flickity-prev-next-button {
            background: rgba(255, 255, 255, 0.5);
        }
  }

  &__link-button {
    background: $colorPurple;
    border: none;
    color: $colorWhite;
    display: inline-block;
    line-height: 1;
    padding: 10px 30px 13px;
  }
}
