$projectMenuItemMargin: 15px;
$projectMenuItemMarginLargePlus: 10px;

.project-menu {
  display: none;

  .no-js & {
    display: none;
  }

  .js-error & {
    display: none;
  }

  @include bigger-than($widthM) {
    pointer-events: none;
    display: block;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
  }

  &__marker {
    width: 40px;
    height: 1px;
    background: $colorLightGray;
    position: fixed;
    right: 0;
    top: 50%;
  }

  &__inner {
    @include bigger-than($widthM) {
      height: 22px + $projectMenuItemMargin;
      position: relative;
    }

    @include bigger-than($widthMPlus) {
      height: 34px + $projectMenuItemMargin;
    }

    @include bigger-than($widthL) {
      height: 46px + $projectMenuItemMargin;
    }

    @include bigger-than($widthLPlus) {
      height: 60px + $projectMenuItemMarginLargePlus;
    }

    @include bigger-than($widthXL) {
      height: 76px + $projectMenuItemMarginLargePlus;
    }
  }

  &__index {
    @include bigger-than($widthM) {
      pointer-events: auto;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__item {
    @include bigger-than($widthM) {
      display: block;
      font-size: 22px;
      line-height: 1;
      margin-bottom: $projectMenuItemMargin;
      position: relative;
      z-index: 2;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include bigger-than($widthMPlus) {
      line-height: 34px;
    }

    @include bigger-than($widthL) {
      line-height: 46px;
    }

    @include bigger-than($widthLPlus) {
      line-height: 60px;
      margin-bottom: $projectMenuItemMarginLargePlus;
    }

    @include bigger-than($widthXL) {
      line-height: 76px;
    }

    &.-is-active {
      opacity: 0;
    }
  }

  &__link {
    position: relative;
    color: $colorLightGray;
    transition: color 0.2s;

    &:hover {
      color: $colorBlack;
    }

    &:before {
      @include bigger-than($widthM) {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 4px;
        background: $colorLightGray;
        top: 50%;
        margin-top: 2px;
        transform: translateY(-50%);
        transition: height 0.4s;
        left: -$outerMarginMedium/2;
      }

      @include bigger-than($widthMPlus) {
        left: -$outerMarginMediumPlus/2;
      }

      @include bigger-than($widthL) {
        left: -$outerMarginLarge/2;
      }

      @include bigger-than($widthLPlus) {
        left: -$outerMarginLargePlus/2;
      }

      @include bigger-than($widthXL) {
        left: -$outerMarginXLarge/2;
      }
    }

    span {
      opacity: 1;
      transition: opacity 0.3s;
      white-space: nowrap;
      // display: b
    }

    &.-is-active {
      .js:not(.js-error) & {
        span {
          opacity: 0;
        }

        &:before {
          @include bigger-than($widthM) {
            height: 20px;
          }

          @include bigger-than($widthMPlus) {
            height: 40px;
          }

          @include bigger-than($widthL) {
            height: 40px;
          }

          @include bigger-than($widthLPlus) {
            height: 50px;
          }

          @include bigger-than($widthXL) {
            height: 60px;
          }
        }
      }

    }
  }

  &__titles {
    display: none;

    .js:not(.js-error) & {
      @include bigger-than($widthM) {
        display: block;
        height: 22px + $projectMenuItemMargin;
        overflow: hidden;
        position: relative;
        z-index: 3;
      }

      @include bigger-than($widthMPlus) {
        height: 34px + $projectMenuItemMargin;
      }

      @include bigger-than($widthL) {
        height: 46px + $projectMenuItemMargin;
        top: -2px;
      }

      @include bigger-than($widthLPlus) {
        height: 60px + $projectMenuItemMarginLargePlus;
        top: -3px;
      }

      @include bigger-than($widthXL) {
        height: 76px + $projectMenuItemMarginLargePlus;
        top: -6px;
      }
    }
  }

  &__titles-inner {
    @include bigger-than($widthM) {
      height: 22px + $projectMenuItemMargin;
      position: relative;
    }

    @include bigger-than($widthMPlus) {
      height: 34px + $projectMenuItemMargin;
    }

    @include bigger-than($widthL) {
      height: 46px + $projectMenuItemMargin;
    }

    @include bigger-than($widthLPlus) {
      height: 60px + $projectMenuItemMarginLargePlus;
    }

    @include bigger-than($widthXL) {
      height: 76px + $projectMenuItemMarginLargePlus;
    }
  }

  &__title {
    @include bigger-than($widthM) {
      display: block;
      font-size: 22px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: $projectMenuItemMargin;
      opacity: 0;
      transition: opacity 0.6s;

      &.-is-active {
        opacity: 1;
      }
    }

    @include bigger-than($widthMPlus) {
      font-size: 34px;
    }

    @include bigger-than($widthL) {
      font-size: 46px;
    }

    @include bigger-than($widthLPlus) {
      font-size: 60px;
      margin-bottom: $projectMenuItemMarginLargePlus;
    }

    @include bigger-than($widthXL) {
      font-size: 76px;
    }
  }


}
