.front {
  &__featured {
    width: 100%;
    margin-bottom: 10px;
  }

  &__featured-link {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;

    @include bigger-than($widthM) {
      padding-bottom: 60%;
    }

    .no-touch & {

      .recent__overlay {
        transition: opacity 0.2s;
      }

      &:hover {
        .recent__overlay {
          opacity: 0.4;
        }
      }
    }
  }

  &__featured-text-wrap {
    position: absolute;

    z-index: 3;
    color: $colorWhite;
    -webkit-font-smoothing: antialiased;

    &--middle {
      top: 50%;
      transform: translateY(-50%);
    }

    &--bottom {
      bottom: $outerMarginMedium;

      @include bigger-than($widthMPlus) {
        bottom: $outerMarginMediumPlus;
      }

      @include bigger-than($widthL) {
        bottom: $outerMarginLarge;
      }
    }
  }

  &__featured-name {
    // max-width: 800px;
  }

  &__featured-text {
    margin-top: 1em;
  }

  &__map-wrap {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;

    @include bigger-than($widthM) {
      padding-bottom: 56.25%;
    }
  }

  &__map {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
