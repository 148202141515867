/* =============================================================================
   Non-Semantic Helper Classes
   ========================================================================== */
.invisible { visibility: hidden; }
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }
.sr-only {
	display: block;
	position: absolute;
	overflow: hidden;
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
	border: 0;
	padding: 0;
	margin: 0 -1px -1px 0;
}
