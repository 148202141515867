.menu {
  @include bigger-than($widthMPlus) {
    width: 100%;
    background: transparentize($colorWhite, 0.05);
    padding: 46px 0 34px;

    .js:not(.js-error) & {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
    }

    .dark-header & {
      background: transparentize($colorBlack, 1);
      color: $colorWhite;
    }
  }

  @include bigger-than($widthLPlus) {
    padding: 69px 0 34px;
  }

  &__logo {
    display: block;
    width: 123px;
    height: 29px;
    position: relative;
    top: 0;

    @include bigger-than($widthMPlus) {
      display: inline-block;
      width: 49px;
      height: 97px;
      top: -24px;
    }
  }

  &__logo-element {
    position: absolute;
    display: block;
    left: 0;
    width: 29px;
    svg {
      display: block;
      width: 29px;
    }

    @include bigger-than($widthMPlus) {
      width: 49px;

      svg {
        width: 49px;
      }
    }
  }

  &__logo-text-top {
    display: none;

    @include bigger-than($widthMPlus) {
      display: block;
      top: 0;
      height: 17px;

      svg {
        height: 17px;
      }
    }

    .dark-header & {
      path {
        fill: $colorWhite;
      }
    }
  }

  &__logo-text-bottom {
    display: none;

    @include bigger-than($widthMPlus) {
      display: block;
      bottom: 0;
      height: 17px;

      svg {
        height: 17px;
      }
    }

    .dark-header & {
      path {
        fill: $colorWhite;
      }
    }
  }

  &__logo-text {
    display: block;
    width: 89px;
    height: 29px;
    position: absolute;
    left: auto;
    right: 0;
    top: 0;

    svg {
      width: 89px;
      height: 29px;
    }

    @include bigger-than($widthMPlus) {
      display: none;
    }

    .dark-header & {
      path {
        fill: $colorWhite;
      }
    }
  }

  &__logo-symbol {
    height: 29px;

    svg {
      height: 29px;
    }

    @include bigger-than($widthMPlus) {
      top: 24px;
      height: 49px;

      svg {
        height: 49px;
      }
    }
  }


  &__non-nav {
    @include smaller-than($widthMPlus) {
      z-index: 1000;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: transparentize($colorWhite, 0.05);
      padding: 20px $outerMarginSmall;
      overflow: hidden;

      .dark-header & {
        background: $colorBlack;
        color: $colorWhite;
      }
    }

    @include bigger-than($widthM) {
      padding: 20px $outerMarginMedium;
    }

    @include bigger-than($widthMPlus) {
      padding: 0;
      position: absolute;
      width: 100px;
      top: 0;
      left: 50%;
      height: 100%;
      z-index: 2;
      transform: translateX(-50%);
      text-align: center;
    }
  }

  &__non-nav-inner {
    position: relative;
  }

  &__nav-wrap {
    @include smaller-than($widthMPlus) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      z-index: 1001;
      overflow: hidden;
      transform: translateX(400px);


      .no-js & {
        display: block;
        transform: translateX(400px);
      }

      .js-error & {
        display: block;
        transform: translateX(400px);
      }

      .page-header:target & {
        display: block;
        transform: translateX(0);
      }
    }
  }

  &__nav {
    @include smaller-than($widthMPlus) {

      color: $colorWhite;
      position: absolute;
      top: 0;
      // left: 70px;
      right: 0;
      // width: 100%;
      width: calc( 100% - 70px);
      max-width: 400px;
      height: 100%;
      z-index: 1001;
      overflow: hidden;
      // display: none;

      // .no-js & {
      //   opacity: 0;
      //   display: block;
      //   transform: translateX(100%);
      //   transition: transform 0.4s $easeInOutQuart, opacity 0s 0.4s;
      // }
      //
      // .js-error & {
      //   opacity: 0;
      //   display: block;
      //   transform: translateX(100%);
      //   transition: transform 0.4s $easeInOutQuart, opacity 0s 0.4s;
      // }
      //
      // .page-header:target & {
      //   opacity: 1;
      //   transform: translateX(0);
      //   transition: transform 0.4s $easeInOutQuart, opacity 0s;
      // }

      .menu__item-link {
        font-size: 22px;
      }
    }
  }

  &__nav-outer {
    @include smaller-than($widthMPlus) {
      background: $colorPurpleDark;
      position: absolute;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  &__nav-inner {
    padding-top: 60px;
    padding-left: $outerMarginSmall;
    padding-right: $outerMarginSmall;
    margin-left: auto;
    margin-right: auto;

    @include bigger-than($widthM) {
      padding-left: $outerMarginMedium;
      padding-right: $outerMarginMedium;
    }

    @include bigger-than($widthMPlus) {
      position: relative;
      padding: 0 !important;
      margin: 0;
    }
  }


  &__toggle {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;


    @include bigger-than($widthMPlus) {
      display: none;
    }

    &.-open {
      opacity: 1;
      right: -6px;
      padding: 7px 6px 7px;
    }

    &.-close {
      height: 30px;
      padding: 0;
      right: 19px;
      top: 20px;

      @include bigger-than($widthM) {
        right: 33px;
      }

      path {
        fill: #bc9cc6;
      }
    }
  }

  &__open-bar {
    width: 100%;
    height: 2px;
    background: $colorPurple;
    display: block;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    .dark-header & {
      background: $colorWhite;
    }
  }

  &__project-items {
    display: block;

    @include bigger-than($widthM) {
      display: none;
    }
  }

  &__item {
    @include smaller-than($widthMPlus) {
      display: block;
      position: relative;
    }

    @include bigger-than($widthMPlus) {
      display: inline-block;

      &--left {
        float: left;
      }

      &--right {
        float: right;
      }
    }

    &.-mobile-only {
      display: block;

      @include bigger-than($widthMPlus) {
        display: none;
      }
    }

    &--project {
      &.-is-active {
        color: $colorRed;
        color: #bc9cc6;
        font-weight: $fontWeightBold;

        &:before {
          content: "";
          position: absolute;
          left: -25px;
          top: 34px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 0 6px 6px;
          border-color: transparent transparent transparent #bc9cc6;
        }
      }
    }
  }

  &__item-link {
    display: inline-block;
    padding: 10px 0;
    margin: 10px 0;

    @include bigger-than($widthMPlus) {
      margin: 0;
      display: block;
      padding: 0 0 3px 0;
      position: relative;
      line-height: 46px;
    }

    &--project {
      -webkit-font-smoothing: antialiased;

      .-is-active & {
        font-size: 32px;
      }
    }
  }
}
